import axios from "axios";
import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Container,
  ListGroup,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ZlecWypelnienieModal from "./ZlecWypelnienieModal";
import AnkietaPacjent from "../../pacjent/ankiety/AnkietaForm";
import AnkietaSekcjaPreviewForm from "./AnkietaPreviewForm";
import SurveyTable from "../components/SurveyTable";
import PacjentSurveyListItem from "../components/PacjentSurveyListItem";
import parse from 'html-react-parser';

export default function AnkietaInfoPage() {
  let { idAnkiety } = useParams();

  const [modalShown, setModalShown] = useState(false);
  const [requestErrorMessage, setRequestErrorMessage] = useState("");
  const [currentSurvey, setCurrentSurvey] = useState({});
  const [currentInstances, setCurrentInstances] = useState([]);

  let navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/surveytemplate/${idAnkiety}`)
      .then((response) => {
        setCurrentSurvey(response.data.surveyTemplate);
        setCurrentInstances(response.data.surveyInstances);
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });
  }, []);

  return (
    <Container>
      <Stack direction="horizontal">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate("/pracownik/ankiety")}>
            Ankiety
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {currentSurvey.metadata && currentSurvey.metadata.title}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Button
          variant="light"
          className="m-2 ms-auto"
          onClick={() => setModalShown(true)}
        >
          Zleć Wypełnienie
        </Button>
        <ZlecWypelnienieModal
          templateId={idAnkiety}
          show={modalShown}
          onHide={() => setModalShown(false)}
        />
      </Stack>
      <h2 className="m-3">
        {currentSurvey.metadata && currentSurvey.metadata.title}
      </h2>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="Szczegóły" title="Szczegóły">
          {currentSurvey.metadata && parse(currentSurvey.metadata.description)}
        </Tab>
        <Tab eventKey="Pacjenci" title="Pacjenci">
          <ListGroup>
            {currentInstances
              .sort((a,b) => {
                return  b.surveyStatus - a.surveyStatus
              })
              .map((s) => (
                <PacjentSurveyListItem surveyInstance={s} />              
              ))}
          </ListGroup>
        </Tab>
        <Tab eventKey="Wyniki" title="Tabela wyników">
          <SurveyTable templateId={idAnkiety}/>
        </Tab>
        <Tab eventKey="Pytania" title="Pytania">
          {currentSurvey.sections &&
            currentSurvey.sections.map((section) => {
              return (
                <AnkietaSekcjaPreviewForm
                  key={section.runningNumber}
                  sectionNumber={section.runningNumber}
                  sectionHeader={section.header}
                  questions={section.questions}
                />
              );
            })}
        </Tab>
      </Tabs>
    </Container>
  );
}
