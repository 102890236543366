import { Container, Form } from "react-bootstrap";

export default function PreviewQuestionAnswered({ question, filled=false }) {

  let style = question.status == 3 ? "text-danger" : ""

  return (
    <Container>
      <Form>
        <h5 className={style} >{question.text}</h5>

        <div className="mb-3">

          {question.questionType === 2 ? 
            <Form.Control type="text" id={`open${question.questionNumber}`} value={question.answers[0] && question.answers[0].text} disabled/>
          : question.possibleAnswers.map((a) => (
            <Form.Check
              key={a.runningNumber}
              inline
              label={a.text}
              name="group1"
              type={"checkbox"}
              id={a.runningNumber}
              disabled={true}
              checked={filled && question.answers.map((ans) => ans.runningNumber).includes(a.runningNumber)}
            />
          ))}
        </div>
      </Form>
    </Container>
  );
}
