import { useContext } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../authContext";

export default function NavbarPracownik() {
  let location = useLocation();
  let navigate = useNavigate();

  const authContext = useContext(AuthContext);

  return (
    <Navbar bg="light" data-bs-theme="light" className="mb-5 d-print-none">
      <Container>
        <Navbar.Brand href="/">Ankiety COMS</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              onClick={() => navigate("/pracownik/pacjenci")}
              className={location.pathname.includes("/pacjenci") && "fw-bold"}
            >
              Pacjenci
            </Nav.Link>
            <Nav.Link
              onClick={() => navigate("/pracownik/ankiety")}
              className={location.pathname.includes("/ankiety") && "fw-bold"}
            >
              Ankiety
            </Nav.Link>
          </Nav>
          <Button
            onClick={() => {
              authContext.logout();
              navigate("/");
            }}
          >
            Wyloguj
          </Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
