import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, ListGroup, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function ZlecWypelnieniePacjentowiModal({
  patientId,
  show,
  onHide,
}) {
  let navigate = useNavigate();

  const [patientPesel, setPatientPesel] = useState("");
  const [requestErrorMessage, setRequestErrorMessage] = useState("");
  const [surveyList, setSurveyList] = useState();

  useEffect(() => {
    axios
      .get("/SurveyTemplate/summary")
      .then((response) => {
        setSurveyList(response.data);
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });
  }, []);

  const assignSurvey = (templateId) => {
    axios
      .post(`/SurveyTemplate/${templateId}?patientId=${patientId}`)
      .catch((error) => {
        console.log(error.message);
        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });

    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ background: "rgba(0, 0, 0, 0.6)" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Zleć wypełnienie ankiety
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="m-2">Wybierz ankietę do wypełnienia przez pacjenta:</h6>
        <ListGroup>
          {surveyList && surveyList.map((s) => {
            return (
              <ListGroup.Item key={s.templateId} action onClick={() => assignSurvey(s.templateId)}>
                {s.title}
              </ListGroup.Item>
            );
          })}
        </ListGroup>
        {requestErrorMessage && (
          <h3 className="text-danger">{requestErrorMessage}</h3>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
