import axios from "axios";
import React, { useEffect, useState } from "react";

export const AuthContext = React.createContext({
  isAuth: false,
  isEmployee: false,
  loginAsEmployee: () => {},
  loginAsPatient: () => {},
  logout: () => {},
  errorMessage: ""
});

const setHeaders = (username, password) => {
  axios.defaults.headers.common['Authorization'] = `${username}@${password}`;
}

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {

    let username = window.localStorage.getItem("username")
    let password = window.localStorage.getItem("password");
    let type = window.localStorage.getItem("type");


    if(username && password){
      setIsAuthenticated(true);
      setIsEmployee(type === "Employee");
      setErrorMessage("")
      setHeaders(username, password)
    }

  }, []);

  const loginEmployeeHandler = (username, password) => {
    setErrorMessage("")
    axios
      .post("/authentication/login", null, {
        params: { username: username, password: password },
      })
      .then((resp) => {
        setIsAuthenticated(true);
        setIsEmployee(true);

        window.localStorage.setItem("username", username);
        window.localStorage.setItem("password", password);
        window.localStorage.setItem("type", "Employee");

        setHeaders(username, password)
      })
      .catch((err) => {
        setErrorMessage("Zła Nazwa użytkownika/PESEL lub hasło")
        console.log(err);
      });
  };

  const loginPatientHandler = (pesel, password) => {
    setErrorMessage("")
    axios
      .post("/authentication/login", null, {
        params: { username: pesel, password: password },
      })
      .then((resp) => {
        setIsAuthenticated(true);
        setIsEmployee(false);
    
        window.localStorage.setItem("username", pesel);
        window.localStorage.setItem("password", password);
        window.localStorage.setItem("type", "Patient");
    
        setHeaders(pesel, password)
      })
      .catch((err) => {
        setErrorMessage("Zła Nazwa użytkownika/PESEL lub hasło")
        console.log(err);
      });

  };

  const logoutHandler = () => {
    setIsAuthenticated(false);
    setIsEmployee(false);
    window.localStorage.clear();
  }

  return (
    <AuthContext.Provider
      value={{
        loginAsEmployee: loginEmployeeHandler,
        loginAsPatient: loginPatientHandler,
        logout: logoutHandler,
        isAuth: isAuthenticated,
        isEmployee: isEmployee,
        errorMessage: errorMessage
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
