import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import ChoiceQuestion from "./components/ChoiceQuestion";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import OpenQuestion from "./components/OpenQuestion";

export default function AnkietaPacjent() {
  const [requestErrorMessage, setRequestErrorMessage] = useState("");

  const [currentSection, setCurrentSection] = useState({});
  const [currentAnswers, setCurrentAnswers] = useState({});
  const [currentOpenAnswers, setCurrentOpenAnswers] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [surveyMetadata, setSurveyMetadata] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  let { idAnkiety } = useParams();

  const clearAnswers = () => {

    let answersCopy = [...currentAnswers]

    Object.keys(answersCopy).forEach((a) => (answersCopy[a] = []))

    setCurrentAnswers(answersCopy)
  }

  const initState = (section) => {
    let answersInit = {};
    section.questions.forEach((q) => {
      answersInit[q.runningNumber] = [];
    });
    setCurrentAnswers(answersInit);
  };

  const getSurveyMetadata = () => {
    axios
      .get(`/SurveyTemplate/details/${idAnkiety}`)
      .then((response) => {
        setSurveyMetadata(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNextSection = () => {
    axios
      .get(`/SurveyInstance/${idAnkiety}/nextSection`)
      .then((section) => {
        if (section.status == 204) {
          navigate("/pacjent/ankiety");
        }

        setCurrentSection(section.data);
        initState(section.data);
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          setIsLoading(false)
          return;
        }

        setRequestErrorMessage(error.message);
        setIsLoading(false)
      });
  };

  const sendSectionSubmit = (submittedQuestions) => {
    setIsLoading(true);
    axios
      .post(
        `/SurveyInstance/${idAnkiety}/${currentSection.runningNumber}`,
        submittedQuestions
      )
      .then((response) => {
        getNextSection();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          setIsLoading(false)
          return;
        }

        setRequestErrorMessage(error.message);
        setIsLoading(false)
      });
  };

  useEffect(() => {
    getSurveyMetadata();
    getNextSection();
  }, []);

  const setAnswerChecked = (questionNumber, answers) => {
    setErrorMessage("");
    const currentCopy = { ...currentAnswers };
    currentCopy[questionNumber] = answers;
    setCurrentAnswers(currentCopy);
  };

  const onAnswerChanged = (questionNumber, value) => {
    setAnswerChecked(questionNumber, value);
  }

  const submitSection = () => {
    let sectionAnswers = [];
    let allAnswered = true;

    let missing = [];

    Object.keys(currentAnswers).forEach((a) => {
      if (currentAnswers[a] === undefined || currentAnswers[a].length == 0) {
        missing.push(parseInt(a) + 1);
        allAnswered = false;
      }

      let answerObject = {
        questionNumber: a
      }
      
      if(typeof(currentAnswers[a]) == "string"){
        answerObject["openAnswerText"] = currentAnswers[a]
      }else{
        answerObject["answersNumbers"] = currentAnswers[a]
      }

      sectionAnswers.push(answerObject);
    });

    if (!allAnswered) {
      setErrorMessage(
        "Odpowiedz na wszystkie pytania. Brakujące pytania: " +
          missing.join(",")
      );
      return;
    }
    const submittedQuestions = {
      questions: sectionAnswers,
    };
    sendSectionSubmit(submittedQuestions);
  };

  return (
    <Container>
      <h3>{surveyMetadata.title}</h3>
      <h4 className="m-4">{currentSection.title}</h4>

      {currentSection.questions &&
        currentSection.questions.map((question) => {
          return question.questionType !== 2 ? (
            <ChoiceQuestion
              key={`section${currentSection.runningNumber}${question.runningNumber}`}
              id={`section${currentSection.runningNumber}${question.runningNumber}`}
              questionNumber={question.runningNumber}
              questionText={question.text}
              answers={question.possibleAnswers}
              singleChoice={question.questionType === 0}
              preview={false}
              currentAnswers={currentAnswers[question.runningNumber]}
              setAnswerChecked={(e) =>
                setAnswerChecked(question.runningNumber, e)
              }
            />
          ) : (
            <OpenQuestion
              key={`section${currentSection.runningNumber}${question.runningNumber}`}
              id={`section${currentSection.runningNumber}${question.runningNumber}`}
              questionNumber={question.runningNumber}
              questionText={question.text}
              preview={false}
              currentAnswer={currentAnswers[question.runningNumber]}
              onAnswerChanged={onAnswerChanged}
            />
          );
        })}

      {errorMessage.length > 0 && (
        <h5 className="m-2 text-danger">{errorMessage}</h5>
      )}

      <Button
        className="mt-4 mb-4"
        disabled={isLoading}
        onClick={() => submitSection()}
      >
        Zatwierdź
      </Button>

      {requestErrorMessage && (
        <h3 className="text-danger">{requestErrorMessage}</h3>
      )}
    </Container>
  );
}
