import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AnkietaFormModal from "./AnkietaFormModal";
import { exportSurveyToCSV } from "../../../utils/csvExporter";

export default function SurveyTable({ templateId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [surveyTemplate, setSurveyTemplate] = useState({});
  const [surveyInstance, setSurveyInstance] = useState({});
  const [requestErrorMessage, setRequestErrorMessage] = useState("");
  const [modalShown, setModalShown] = useState(false);
  const [modalSurveyId, setModalSurveyId] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`/surveytemplate/${templateId}`)
      .then((response) => {
        setSurveyTemplate(response.data.surveyTemplate);
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });

    axios
      .get(`/surveytemplate/${templateId}/scores`)
      .then((response) => {
        setSurveyInstance(response.data);
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });
  }, []);

  return (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Imię</th>
          <th>Nazwisko</th>
          <th>PESEL</th>
          {surveyTemplate.sections &&
            surveyTemplate.sections.map((s) => (
              <th>{s.header ? s.header : s.runningNumber + 1}</th>
            ))}
          <th></th>
          <th>
            <Button variant="link" size="sm" onClick={() => exportSurveyToCSV(templateId)}>
              Eksport do CSV
            </Button>
          </th>
        </tr>
      </thead>
      <tbody>
        {surveyInstance.surveyScores &&
          surveyInstance.surveyScores.map((s) => {
            return (
              <tr>
                <td>{s.patient.name}</td>
                <td>{s.patient.surname}</td>
                <td>{s.patient.pesel}</td>
                {s.sectionScores
                  .sort((a, b) => a.runningNumber - b.runningNumber)
                  .map((section) => {
                    {
                      return section.requiresAttention ? (
                        <td style={{ backgroundColor: "pink" }}>
                          {section.score}
                        </td>
                      ) : (
                        <td>{section.score}</td>
                      );
                    }
                  })}
                <td>
                  <Button
                    className="sm"
                    onClick={() => {
                      setModalShown(true);
                      setModalSurveyId(s.instanceId);
                    }}
                    variant="link"
                    size="sm"
                  >
                    Odpowiedzi
                  </Button>
                </td>
                <td>
                  <Button
                    className="sm"
                    onClick={() => {
                      navigate(`/pracownik/raport/${s.instanceId}`);
                    }}
                    variant="link"
                    size="sm"
                  >
                    Raport
                  </Button>
                </td>
              </tr>
            );
          })}
      </tbody>
      <AnkietaFormModal
        surveyId={modalSurveyId}
        show={modalShown}
        onHide={() => setModalShown(false)}
      />
    </Table>
  );
}
