import {
    Accordion,
    Badge,
    Button,
    Card,
    Container,
    ListGroup,
    Row,
    Stack,
  } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function PacjentListItem({patient, patientSurveys}) {

    const navigate = useNavigate();
    
    return (
        <Accordion.Item eventKey={patient.patientId}>
        <Accordion.Header>
          <div><b>{patient.name} {patient.surname}</b> (PESEL: {patient.pesel})</div>
        </Accordion.Header>
        <Accordion.Body>
          <Container>
            <Card>
              <Card.Body>
                <Stack direction="horizontal" gap={2}>
                  <Button variant="success" disabled>
                    Ukończone <Badge bg="success">{patientSurveys.completedSurveysCount}</Badge>
                  </Button>
                  <Button variant="secondary" disabled>
                    Zlecone <Badge bg="dark">{patientSurveys.assignedSurveysCount}</Badge>
                  </Button>
                  <Button variant="danger" disabled>
                    Wymaga uwagi <Badge bg="danger">{patientSurveys.requiresAttentionSurveysCount}</Badge>
                  </Button>
                  <Button
                    onClick={() => navigate(`/pracownik/pacjenci/${patient.patientId}`)}
                    className="p-2 ms-auto"
                    variant="primary"
                  >
                    Przejdź do pacjenta
                  </Button>
                </Stack>
              </Card.Body>
            </Card>
          </Container>
        </Accordion.Body>
      </Accordion.Item>
    )

}