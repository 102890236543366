import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function ZlecWypelnienieModal({templateId, show, onHide}) {
  let navigate = useNavigate();

  const [patientPesel, setPatientPesel] = useState("");
  const [requestErrorMessage, setRequestErrorMessage] = useState("");


  const assignSurvey = (pesel) => {
    axios.post(`/SurveyTemplate/${templateId}?patientPESEL=${patientPesel}`)
    .catch(error => {
      console.log(error.message);
      if(error.response){
        setRequestErrorMessage(error.response.data.title);
        return;
      }

      setRequestErrorMessage(error.message)
    })

    onHide();
  };

  return (
    <Modal
    show={show}
    onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ background: "rgba(0, 0, 0, 0.6)" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Zleć wypełnienie ankiety
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label htmlFor="inputPassword5">
          PESEL pacjenta, któremu zlecane jest wypełnienie ankiety:{" "}
        </Form.Label>
        <Form.Control
          type="number"
          id="inputPESEL"
          value={patientPesel}
          onChange={(e) => setPatientPesel(e.target.value)}
          aria-describedby="peselBlock"
        />
        <Form.Text id="peselBlock" muted>
          Przed zleceniem pacjentowi wypełnienia ankiety należy go najpierw
          zarejestrować w zakładce <a href="/pracownik/pacjenci">Pacjenci</a>
        </Form.Text>
        {requestErrorMessage && <h3 className="text-danger">{requestErrorMessage}</h3>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            assignSurvey();
          }}
        >
          Zleć wypełnienie
        </Button>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
