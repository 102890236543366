import axios from "axios";
import { useEffect, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Container,
  ListGroup,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import AnkietaListItem from "../components/AnkietaListItem";

export default function AnkietyPage() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [requestErrorMessage, setRequestErrorMessage] = useState("");
  const [surveyList, setSurveyList] = useState();

  useEffect(() => {
    axios
      .get("/SurveyTemplate/summary")
      .then((response) => {
        setSurveyList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });
  }, []);

  return (
    <Container>
      {requestErrorMessage && (
        <h3 className="text-danger">{requestErrorMessage}</h3>
      )}
      <h2>Lista Ankiet</h2>

      {isLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      <Accordion alwaysOpen>
        {surveyList &&
          surveyList.map((s) => (
            <AnkietaListItem key={s.templateId} survey={s} />
          ))}
      </Accordion>
    </Container>
  );
}
