import { Container, Form } from "react-bootstrap";

export default function OpenQuestion({
  questionNumber,
  questionText,
  preview,
  currentAnswer,
  onAnswerChanged
}) {
  return (
    <Container className="mb-2">
      <Form>
        <h5>{questionText}</h5>
        <Form.Control type="text" id={`open${questionNumber}`} value={currentAnswer} onChange={(e) => onAnswerChanged(questionNumber, e.target.value)}/>
      </Form>
    </Container>
  );
}
