import axios from "axios";
import { useEffect, useState } from "react";
import { Card, CardGroup, Container, Form, Tab, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";

export default function SurveyReportPage() {
  let { idAnkiety, idInstance } = useParams();
  const [surveyInstance, setSurveyInstance] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .get("/surveyinstance/" + idInstance)
      .then((response) => {
        setIsLoading(false);
        setSurveyInstance(response.data);
        setPatientDetails(response.data.patients[0])

        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [idInstance]);

  return (
    <div style={{ fontSize: 12 }}>
      <p className="m-4 d-print-none">W celu zapisania w formacie PDF: <b>ctrl+p</b> a następnie z listy wybrać <b>"Zapisz jako PDF"</b> </p>

      <h4>{patientDetails.name} {patientDetails.surname} PESEL: {patientDetails.pesel}</h4>

      {
        surveyInstance.sections &&
        surveyInstance.sections.map((section) => {
          return (
            <>
              <h6>{section.header ? section.header : "Section " + (section.runningNumber+1)}</h6>
              {section.questions.map((question) => {
                return (
                  <CardGroup>
                    <Card border="light">
                      <Card.Body className="p-0">{question.text}</Card.Body>
                    </Card>
                    <Card border="light">
                      <Card.Body className="p-0">
                        {question.questionType === 2
                          ? question.answers[0].text
                          : question.possibleAnswers.map((option) => {
                              let checked = question.answers.some((e) => {
                                return e.runningNumber == option.runningNumber;
                              });

                              return (
                                <Form.Check
                                  inline
                                  disabled
                                  type={"checkbox"}
                                  label={option.text}
                                  checked={checked}
                                />
                              );
                            })}
                      </Card.Body>
                    </Card>
                  </CardGroup>
                );
              })}
            </>
          );
        })}
    </div>
  );
}