import axios from "axios";
import { useState } from "react";
import { Alert, Button, Container, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function RejestracjaPacjenta() {
  const [requestErrorMessage, setRequestErrorMessage] = useState("");

  const [peselNumber, setPeselNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [newUserCode, setNewUserCode] = useState(-1);
  const [isLoading, setIsLoadingStatus] = useState(false);


  const navigate = useNavigate();

  const clearInputs = () => {
    setPeselNumber("")
    setFirstName("")
    setLastName("")
  }

  const registerUser = () => {
    setIsLoadingStatus(true);
    axios
      .post(
        `/Patient?name=${firstName}&surname=${lastName}&PESEL=${peselNumber}`
      )
      .then((response) => {
        setIsLoadingStatus(false);
        setNewUserCode(response.data);
        clearInputs();
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });
  };

  return (
    <Container>
      <h1 className="mb-4">Rejestacja pacjenta</h1>
      <Form>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>PESEL</Form.Label>
          <Form.Control
            type="number"
            value={peselNumber}
            onChange={(e) => setPeselNumber(e.currentTarget.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Imię</Form.Label>
          <Form.Control
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.currentTarget.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Nazwisko</Form.Label>
          <Form.Control
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.currentTarget.value)}
          />
        </Form.Group>
        {requestErrorMessage && (
          <h3 className="text-danger">{requestErrorMessage}</h3>
        )}
        <Button
          variant="primary"
          className="mt-3"
          onClick={() => registerUser()}
        >
          Zarejestruj
        </Button>
      </Form>

      {isLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {newUserCode >= 0 && 
        <Alert className="m-4" key="success" variant="success">
          Rejestracja pomyślna! Hasło pacjenta to: <h3 className="m-2">{newUserCode}</h3> Podaj hasło
          pacjentowi i poproś o zapamiętanie! Hasło w połączeniu z numerem PESEL wymagane jest do zalogowania.<br/>
          W razie gdy pacjent zgubi hasło zarejestruj
          pacjenta jeszcze raz używajac numeru PESEL<br/>
          <a href="/pracownik/pacjenci">Powrót do listy pacjentów</a>
        </Alert>
      }
    </Container>
  );
}
