import { useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Container,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import AnkietaFormModal from "./AnkietaFormModal";

export default function PacjentAnkietaListItem({ survey, patientId }) {
  const navigate = useNavigate();

  const [modalShown, setModalShown] = useState(false);

  let label = "Czeka na wypełnienie";
  let color = "primary";

  switch (survey.metadata.status) {
    case 0:
      label = "Czeka na wypełnienie";
      color = "secondary";
      break;
    case 1:
      label = "W trakcie wypełniania";
      color = "primary";
      break;
    case 2:
      label = "Wypełniona";
      color = "success";
      break;
    case 3:
      label = "Wymaga uwagi!";
      color = "danger";
      break;
  }

  const surveyStatus = {
    label: label,
    color: color,
  };

  return (
    <ListGroup.Item
      as="li"
      className="d-flex justify-content-between align-items-center"
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">
          {survey.metadata.title}{" "}
          <Badge bg={surveyStatus.color} pill>
            {surveyStatus.label}
          </Badge>
        </div>
        {/* {metadata.description} */}
      </div>

      {survey.metadata.status >= 2 && (
        <Button
          onClick={() => setModalShown(true)}
          className="p-2 ms-auto"
          variant="primary"
        >
          Zobacz odpowiedzi
        </Button>
      )}
      <AnkietaFormModal
        surveyId={survey.instanceId}
        show={modalShown}
        onHide={() => setModalShown(false)}
      />
    </ListGroup.Item>
  );
}
