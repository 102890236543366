import { useContext, useState } from "react";
import NavbarPracownik from "../pracownik/NavbarPracownik";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
  Stack,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../authContext";

export default function LoginScreen() {
  const navigate = useNavigate();

  const [peselNumber, setPeselNumber] = useState("");
  const [patientPassword, setPatientPassword] = useState("");

  const [empUsername, setEmpUsername] = useState("");
  const [empPassword, setEmpPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");

  const authContext = useContext(AuthContext);

  return (
    <Container className="d-flex mt-4 justify-content-center h-100">
      <Stack>
        <h3>Ankiety COMS</h3>
        <Card className="m-4" style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title>Pacjent</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Logowanie jako pacjent
            </Card.Subtitle>

            <Form>
              <Form.Group
                className="mb-3"
                controlId="pacjent.pesel"
              >
                <Form.Label>PESEL</Form.Label>
                <Form.Control
                  type="number"
                  value={peselNumber}
                  onChange={(e) => setPeselNumber(e.currentTarget.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="pacjent.kod"
              >
                <Form.Label>Kod</Form.Label>
                <Form.Control
                  type="password"
                  value={patientPassword}
                  onChange={(e) => setPatientPassword(e.currentTarget.value)}
                />
              </Form.Group>
            </Form>

            <Button
              variant="primary"
              className="m-2"
              onClick={() => {
                setErrorMessage("")
                authContext.loginAsPatient(peselNumber, patientPassword);
                if(authContext.isAuth){
                  navigate("/");
                }
              }}
            >
              Zaloguj
            </Button>
          </Card.Body>
        </Card>

        <Card className="m-4" style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title>Prowadzący</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Logowanie jako prowadzący
            </Card.Subtitle>

            <Form>
              <Form.Group
                className="mb-3"
                controlId="pracownik.nazwa"
              >
                <Form.Label>Nazwa użytkownika</Form.Label>
                <Form.Control
                  type="text"
                  value={empUsername}
                  onChange={(e) => setEmpUsername(e.currentTarget.value)}
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="pracownik.haslo"
              >
                <Form.Label>Hasło</Form.Label>
                <Form.Control
                  type="password"
                  value={empPassword}
                  onChange={(e) => setEmpPassword(e.currentTarget.value)}
                />
              </Form.Group>
            </Form>

            <Button
              variant="secondary"
              className="m-2"
              onClick={() => {
                setErrorMessage("")
                authContext.loginAsEmployee(empUsername, empPassword);
                if(authContext.isAuth){
                  navigate("/");
                }
              }}
            >
              Zaloguj
            </Button>
          </Card.Body>
        </Card>
        <h5 className="text-danger">{authContext.errorMessage}</h5>
      </Stack>
    </Container>
  );
}
