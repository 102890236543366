import {
  Accordion,
  Badge,
  Button,
  Card,
  Container,
  ListGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import parse from 'html-react-parser'

export default function AnkietaListItem({ survey }) {
  const navigate = useNavigate();

  return (
    <Accordion.Item eventKey={survey.templateId}>
      <Accordion.Header>
        <div>{survey.title}</div>
      </Accordion.Header>
      <Accordion.Body>
        <Container>
          <Card>
            <Card.Body>
              <Card.Text>{parse(survey.description)}</Card.Text>
              <Stack direction="horizontal" gap={2}>
                <Button variant="success" disabled>
                  Ukończone{" "}
                  <Badge bg="success">{survey.completedSurveysCount}</Badge>
                </Button>
                <Button variant="secondary" disabled>
                  Zlecone <Badge bg="dark">{survey.assignedSurveysCount}</Badge>
                </Button>
                <Button variant="danger" disabled>
                  Wymaga uwagi{" "}
                  <Badge bg="danger">
                    {survey.requiresAttentionSurveysCount}
                  </Badge>
                </Button>
                <Button
                  onClick={() =>
                    navigate(`/pracownik/ankiety/${survey.templateId}`)
                  }
                  className="p-2 ms-auto"
                  variant="primary"
                >
                  Przejdź do ankiety
                </Button>
              </Stack>
            </Card.Body>
          </Card>
        </Container>
      </Accordion.Body>
    </Accordion.Item>
  );
}
