import { useContext } from "react";
import { AuthContext } from "./authContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginScreen from "./routes/login/LoginPage";
import AnkietyPage from "./routes/pracownik/ankiety/AnkietyPage";
import PacjenciPage from "./routes/pracownik/pacjenci/PacjenciPage";
import PracownikLayout from "./routes/pracownik/PracownikLayout";
import ErrorPage from "./routes/ErrorPage";
import AnkietaInfoPage from "./routes/pracownik/ankiety/AnkietaInfoPage";
import RejestracjaPacjenta from "./routes/pracownik/pacjenci/RejestracjaPacjenta";
import PacjentInfoPage from "./routes/pracownik/pacjenci/PacjentInfoPage";
import PacjentLayout from "./routes/pacjent/PacjentLayout";
import AnkietaPacjent from "./routes/pacjent/ankiety/AnkietaForm";
import AnkietyPacjentPage from "./routes/pacjent/ankiety/AnkietyPacjentPage";
import AnkietaHomePage from "./routes/pacjent/ankiety/AnkietaHome";
import SurveyReportPage from "./routes/pracownik/components/RaportPage";

export default function ProtectedRouter() {
  const authContext = useContext(AuthContext);

  let routes = (
    <Routes>
      <Route path="/" element={<LoginScreen />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );

  if (authContext.isAuth) {
    if (authContext.isEmployee) {
      routes = (
        <>
          <PracownikLayout />
          <Routes>
            <Route
              path="/pracownik/ankiety/*"
              element={<PracownikAnkietyRoutes />}
            />
            <Route
              path="/pracownik/pacjenci/*"
              element={<PracownikPacjenciRoutes />}
            />
            <Route
              path="/pracownik/raport/:idInstance"
              element={<SurveyReportPage />}
            />
            <Route path="*" element={<Navigate to="/pracownik/ankiety" />} />
          </Routes>
        </>
      );
    } else {
      routes = (
        <>
          <PacjentLayout />
          <Routes>
            <Route
              path="/pacjent/ankiety/*"
              element={<PacjentRoutes />}
            />
            <Route path="*" element={<Navigate to="/pacjent/ankiety" />} />
          </Routes>
        </>
      );
    }
  }

  return <BrowserRouter>{routes}</BrowserRouter>;
}

const PracownikAnkietyRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AnkietyPage />} />
      <Route path="/:idAnkiety" element={<AnkietaInfoPage />} />
    </Routes>
  );
};

const PracownikPacjenciRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PacjenciPage />} />
      <Route path="/rejestracja" element={<RejestracjaPacjenta />} />
      <Route path="/:idPacjenta" element={<PacjentInfoPage />} />
    </Routes>
  );
};

const PacjentRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<AnkietyPacjentPage />} />
      <Route path="/:idAnkiety" element={<AnkietaHomePage />} />
      <Route path="/:idAnkiety/formularz" element={<AnkietaPacjent />} />
    </Routes>
  );
};
