import { Alert, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {

  const navigate = useNavigate();

  return (
    <Container>
      <Alert className="m-4" variant="danger">
        <Alert.Heading>Oh snap! This page doesn't exist or there was an error!</Alert.Heading>
        <p>
          Please <a href="/">go back home</a> and try again
        </p>
      </Alert>
    </Container>
  );
}
