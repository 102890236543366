import { Container } from "react-bootstrap";
import ChoiceQuestion from "../../pacjent/ankiety/components/ChoiceQuestion";
import PreviewQuestionAnswered from "../../pacjent/ankiety/components/PreviewQuestion";

export default function AnkietaSekcjaPreviewForm({sectionNumber, sectionTitle, sectionHeader, questions, filled}) {

    return (
        <Container>
          <h3>{sectionHeader ? sectionHeader : "Section " + (sectionNumber+1)}</h3>
    
          {questions && questions.map((question) => {
            return (
              <PreviewQuestionAnswered question={question} filled={filled}/>
            );
          })}    
        </Container>
      );

}