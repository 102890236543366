import { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";

export default function ChoiceQuestion({
  questionNumber,
  questionText,
  answers,
  singleChoice,
  preview,
  currentAnswers,
  setAnswerChecked,
}) {
  const checkType = singleChoice ? "radio" : "checkbox";

  const [selectedAnswers, setSeletctedAnswers] = useState([]);

  const onCheck = (answerNumber, checked) => {
    if (singleChoice) {
      setSeletctedAnswers([answerNumber]);
      setAnswerChecked([answerNumber]);
    } else {
      let currentSelectionCopy = [...selectedAnswers];

      let arrPos = currentSelectionCopy.indexOf(answerNumber);
      if (arrPos >= 0) currentSelectionCopy.splice(arrPos, 1);

      if (checked) currentSelectionCopy.push(answerNumber);

      setSeletctedAnswers(currentSelectionCopy);
      setAnswerChecked(currentSelectionCopy);
    }
  };

  return (
    <Container>
      <Form>
        <h5>{questionText}</h5>

        <div className="mb-3">
          {answers.map((a) => {        
              return <Form.Check
              key={`${a.runningNumber}q${questionNumber}`}
              inline
              label={a.text}
              name={`${a.runningNumber}q${questionNumber}`}
              type={'checkbox'}
              id={`${a.runningNumber}q${questionNumber}`}
              disabled={preview}
              onChange={(e) => {
                onCheck(a.runningNumber, e.target.checked)
                }}
              checked={selectedAnswers.includes(a.runningNumber)}
            /> 
          })}
        </div>
      </Form>
    </Container>
  );
}
