import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";

import LoginScreen from "./routes/login/LoginPage";
import ErrorPage from "./routes/ErrorPage";
import PracownikLayout from "./routes/pracownik/PracownikLayout";
import AnkietyPage from "./routes/pracownik/ankiety/AnkietyPage";
import PacjenciPage from "./routes/pracownik/pacjenci/PacjenciPage";
import AnkietaInfoPage from "./routes/pracownik/ankiety/AnkietaInfoPage";
import PacjentInfoPage from "./routes/pracownik/pacjenci/PacjentInfoPage";
import PacjentLayout from "./routes/pacjent/PacjentLayout";
import AnkietyPacjentPage from "./routes/pacjent/ankiety/AnkietyPacjentPage";
import AnkietaPacjent from "./routes/pacjent/ankiety/AnkietaForm";
import RejestracjaPacjenta from "./routes/pracownik/pacjenci/RejestracjaPacjenta";
import axios from "axios";
import CustomRouter from "./router";
import AuthContextProvider from "./authContext";
import ProtectedRouter from "./protectedRouter";

axios.defaults.baseURL = "https://api.psychologia.coms.pl";


axios.interceptors.request.use(
  (request) => {
    return request;
  },
  (error) => {
    window.location.href = "/error";
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ProtectedRouter />
    </AuthContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
