import axios from "axios";

export const exportSurveyToCSV = (templateId) => {

    axios
      .get(`/surveytemplate/${templateId}/instances`)
      .then((response) => {
        const surveyInstances = response.data;

        if(!surveyInstances) return

        let CSV = "";

        CSV += "PESEL;IMIE;NAZWISKO;DATA;"
        surveyInstances[0].sections.forEach(section => {
            let sNum = section.runningNumber;

            section.questions.forEach(question => {
                let qNum = `${sNum}-${question.runningNumber}`;
                let qScore = `${sNum}-${question.runningNumber}-score`;
                CSV += `${qNum};${qScore};`
            })
        });
        CSV += '\n'

        let row = ""
        surveyInstances.forEach(instance => {
            let patient = instance.patients[0];

            row += `${patient.pesel};${patient.name};${patient.surname};${instance.metadata.assignedDate};`

            instance.sections.forEach(section => {    
                section.questions.forEach(question => {
                    let answers = []
                    let score = 0

                    question.answers.forEach(answer => {
                        answers.push(answer.text)
                        score += answer.score
                    })

                    let answer = answers.join(',')

                    row += `${answer};${score};`
                })
            });

            row += '\n'
            CSV += row
            row = ""
        })
        download(CSV, surveyInstances[0].metadata.title)
      })
      .catch((error) => {
        console.log(error);
      });


}


const download = function (data, fileName) { 
    const blob = new Blob([data], { type: 'text/csv' }); 
    const url = window.URL.createObjectURL(blob) 
    const a = document.createElement('a') 
    a.setAttribute('href', url) 
    a.setAttribute('download', `${fileName}.csv`); 
    a.click() 
} 