import axios from "axios";
import { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Container,
  ListGroup,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ZlecWypelnieniePacjentowiModal from "./ZlecWypelnieniePacjentowiModal";
import PacjentAnkietaListItem from "../components/PacjentAnkietaListItem";
import DeleteUserModal from "./DeleteUserModal";

export default function PacjentInfoPage() {
  let { idPacjenta } = useParams();
  let navigate = useNavigate();

  const [requestErrorMessage, setRequestErrorMessage] = useState("");
  const [modalShown, setModalShown] = useState(false);
  const [deleteModalShown, setDeleteModalShown] = useState(false);

  const [currentPatient, setCurrentPatient] = useState({});
  const [patientSurveys, setPatientSurveys] = useState([]);

  useEffect(() => {
    axios
      .get(`/Patient/${idPacjenta}`)
      .then((response) => {
        setCurrentPatient(response.data.patient);
        setPatientSurveys(response.data.surveyInstances);
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });
  }, []);

  return (
    <Container>
      <Stack direction="horizontal">
        <Breadcrumb>
          <Breadcrumb.Item onClick={() => navigate("/pracownik/pacjenci")}>
            Pacjenci
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {currentPatient.name} {currentPatient.surname}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Button
          variant="light"
          className="m-2 ms-auto"
          onClick={() => setModalShown(true)}
        >
          Zleć Wypełnienie
        </Button>
        <ZlecWypelnieniePacjentowiModal
          patientId={currentPatient.patientId}
          show={modalShown}
          onHide={() => setModalShown(false)}
        />
        <DeleteUserModal 
          patientId={currentPatient.patientId}
          patientPesel={currentPatient.pesel}
          show={deleteModalShown}
          onHide={() => setDeleteModalShown(false)}
        />
      </Stack>
      <h2 className="m-3">
        {currentPatient.name} {currentPatient.surname}
      </h2>
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="Informacje" title="Informacje">
          Numer PESEL: <h3>{currentPatient.pesel}</h3>
          <Button
          className="mt-5 ms-auto"
          variant="danger"
          onClick={() => setDeleteModalShown(true)}
        >
          Usuń pacjenta wraz z jego ankietami
        </Button>
        </Tab>
        <Tab eventKey="Ankiety" title="Ankiety">
          <ListGroup>
            {patientSurveys && patientSurveys
                .sort((a, b) => {
                  return b.surveyStatus - a.surveyStatus;
                })
                .map((s) => <PacjentAnkietaListItem key={s.instanceId} survey={s} patientId={idPacjenta}/>)}
          </ListGroup>
        </Tab>
      </Tabs>
    </Container>
  );
}
