import { useEffect, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Container,
  ListGroup,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AnkietaListItem from "./components/AnkietaListItem";

export default function AnkietyPacjentPage() {
  const navigate = useNavigate();

  const patientId = "3fa85f64-5717-4562-b3fc-2c963f66afa7";

  const [requestErrorMessage, setRequestErrorMessage] = useState("");
  const [availableSurveys, setAvailableSurveys] = useState([]);
  const [isLoading, setIsLoadingStatus] = useState(true);

  useEffect(() => {
    axios
      .get(`/Patient/me`)
      .then((response) => {
        setAvailableSurveys(response.data.surveySummaries);
        setIsLoadingStatus(false);
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });
  }, []);

  return (
    <Container>
      {requestErrorMessage && (
        <h3 className="text-danger">{requestErrorMessage}</h3>
      )}
      <h2>Lista Ankiet</h2>

      {isLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      <ListGroup>
        {!isLoading &&
          availableSurveys
            .sort((a, b) => {
              return a.surveyStatus - b.surveyStatus;
            })
            .map((s) => <AnkietaListItem key={s.instanceId} survey={s} />)}
      </ListGroup>
    </Container>
  );
}
