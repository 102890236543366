import { useState } from "react";
import { Badge, Button, ListGroup } from "react-bootstrap";
import AnkietaFormModal from "./AnkietaFormModal";

export default function PacjentSurveyListItem({ surveyInstance }) {
  let label = "Czeka na wypełnienie";
  let color = "primary";

  const [modalShown, setModalShown] = useState(false);

  switch (surveyInstance.surveyStatus) {
    case 0:
      label = "Czeka na wypełnienie";
      color = "secondary";
      break;
    case 1:
      label = "W trakcie wypełniania";
      color = "primary";
      break;
    case 2:
      label = "Wypełniona";
      color = "success";
      break;
    case 3:
      label = "Wymaga uwagi!";
      color = "danger";
      break;
  }

  const surveyStatus = {
    label: label,
    color: color,
  };

  return (
    <ListGroup.Item
      as="li"
      className="d-flex justify-content-between align-items-center"
    >
      <div className="ms-2 me-auto">
        <div className="fw-bold">
          <Badge className="m-2" bg={surveyStatus.color} pill>
            {surveyStatus.label}
          </Badge>
          {surveyInstance.patient.name} {surveyInstance.patient.surname}{" "}
          {surveyInstance.patient.pesel}
        </div>
      </div>

      {surveyInstance.surveyStatus > 1 && (
        <Button
          onClick={() => setModalShown(true)}
          className="p-2 ms-auto"
          variant="link"
          size="sm"
        >
          Wyświetl odpowiedzi
        </Button>
      )}
      <AnkietaFormModal
        surveyId={surveyInstance.instanceId}
        show={modalShown}
        onHide={() => setModalShown(false)}
      />
    </ListGroup.Item>
  );
}
