import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function DeleteUserModal({patientId, patientPesel, show, onHide}) {

  const [requestErrorMessage, setRequestErrorMessage] = useState("");

  const navigate = useNavigate();


  const deleteUser = (pesel) => {
    axios.delete(`/patient/${patientId}`)
    .then((e) => {
      navigate('/pracownik/pacjenci')
    })
    .catch(error => {
      console.log(error.message);
      if(error.response){
        setRequestErrorMessage(error.response.data.title);
        return;
      }
      setRequestErrorMessage(error.message)
    })

    onHide();
  };

  return (
    <Modal
    show={show}
    onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ background: "rgba(0, 0, 0, 0.6)" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Usuwanie pacjenta wraz z ankietami
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Czy na pewno chcesz usunąć pacjenta o numerze PESEL: <b>{patientPesel}</b>. <br/>
        Tej czynności nie można odwrócić!
        <br/>

        <Button
          onClick={() => {
            deleteUser()
          }}
          variant="danger"
          className="m-5"
        >
          Usuń
        </Button>
        {requestErrorMessage && <h3 className="text-danger">{requestErrorMessage}</h3>}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Zamknij</Button>
      </Modal.Footer>
    </Modal>
  );
}
