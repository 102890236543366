import { Button, Modal, Spinner } from "react-bootstrap";
import AnkietaSekcjaPreviewForm from "../ankiety/AnkietaPreviewForm";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function AnkietaFormModal({ surveyId, show, onHide }) {
  const [surveyInstance, setSurveyInstance] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get("/surveyinstance/" + surveyId)
      .then((response) => {
        setIsLoading(false);
        setSurveyInstance(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [surveyId]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ background: "rgba(0, 0, 0, 0.6)" }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {surveyInstance.metadata && surveyInstance.metadata.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <Button variant="light" className="m-2" onClick={() => navigate(`/pracownik/raport/${surveyId}`)}>
          Wyświetl raport
        </Button>
        {surveyInstance &&
          surveyInstance.sections &&
          surveyInstance.sections.map((section) => {
            return (
              <AnkietaSekcjaPreviewForm
                key={section.runningNumber}
                sectionNumber={section.runningNumber}
                sectionTitle={section.title}
                sectionHeader={section.header}
                questions={section.questions}
                filled={true}
              />
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}
