import axios from "axios";
import { useEffect, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Container,
  Form,
  ListGroup,
  Row,
  Spinner,
  Stack,
} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import PacjentListItem from "../components/PacjentListItem";

export default function PacjenciPage() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [requestErrorMessage, setRequestErrorMessage] = useState("");
  const [patientList, setPatientList] = useState([]);

  const [searchPesel, setSearchPesel] = useState("");

  useEffect(() => {
    axios
      .get("/Patient")
      .then((response) => {
        setPatientList(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);

        if (error.response) {
          setRequestErrorMessage(error.response.data.title);
          return;
        }

        setRequestErrorMessage(error.message);
      });
  }, []);

  return (
    <Container>
      {requestErrorMessage && (
        <h3 className="text-danger">{requestErrorMessage}</h3>
      )}
      <Stack direction="horizontal">
        <h2>Pacjenci</h2>
        <Button
          variant="light"
          className="m-2 ms-auto"
          onClick={() => navigate("/pracownik/pacjenci/rejestracja")}
        >
          Zarejestruj pacjenta
        </Button>
      </Stack>

      <Form.Label htmlFor="inputPassword5"></Form.Label>
      <Form.Control
        type="number"
        id="peselSearch"
        className="mb-4"
        placeholder="Wyszukaj po numerze PESEL:"
        value={searchPesel}
        onChange={(e) => setSearchPesel(e.target.value)}
      />

      {isLoading && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}

      <Accordion alwaysOpen>
        {patientList &&
          patientList.filter(p => p.patient.pesel.startsWith(searchPesel)).reverse().map((p) => (
            <PacjentListItem
              key={p.patient.patientId}
              patient={p.patient}
              patientSurveys={p.patientSurveysSummary}
            />
          ))}
      </Accordion>
    </Container>
  );
}
