import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';

export default function AnkietaHomePage() {
    
    const [requestErrorMessage, setRequestErrorMessage] = useState("");
    let {idAnkiety} = useParams();
    const navigate = useNavigate();

    const [surveyMetadata, setSurveyMetadata] = useState({});

    const getSurveyMetadata = () => {
        axios.get(`/SurveyTemplate/details/${idAnkiety}`)
        .then(response => {
          setSurveyMetadata(response.data)
        })
        .catch(err => {
          console.log(err);
        })
      }

    useEffect(() => {
        getSurveyMetadata();
    }, [])

    return (
        <Container>
            <h2>{surveyMetadata.title}</h2>
            
            {surveyMetadata.description && parse(surveyMetadata.description)}

            <br></br>
            <Button className="mt-4 mb-4" onClick={() => navigate(`/pacjent/ankiety/${idAnkiety}/formularz`)}>
                Rozpocznij
            </Button>
        </Container>
    )


}